<template>
  <v-dialog modal v-model="show" width="840">
    <v-card>
      <v-card-title class="text-xs-center justify-center"
        >Создание пользователей по образцу</v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex mb-5">
            <div class="cardSubheader mr-2">
              Образец:
            </div>
            <span style="color:rgba(0,0,0,.87)">
              {{selectedUser.name}} ({{selectedUser.login}}) id {{selectedUser.id}}
            </span>
          </v-col>
        </v-row>
        <v-row v-for="(user, i) in newUsers" :key="i" class="mt-n6">
          <v-col cols="6">
            <v-text-field
              v-model="user.name"
              label="Имя"
              dense
              outlined
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="user.login"
              label="Логин"
              dense
              outlined
              persistent-hint
              append-outer-icon="mdi-close"
              @click:append-outer="removeUser(i)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" @click="addUser">
              <v-icon class="ml-n2 pl-0">mdi-plus</v-icon><span class="ml-1">Клиент</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="#F5F5F5"
          style="color: rgba(0, 0, 0, 0.87)"
          outlined
          @click="closeDialog"
        >
          Отменить
        </v-btn>
        <v-btn
          color="primary"
          @click="saveDialog"
          class="ml-4"
        >
          Применить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    shown: {
      type: Boolean,
      default: false,
    },
    selectedUser: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data: () => ({
    newUsers: [{name: "", login: ""}],
  }),
  mounted() {
    this.newUsers =  [{name: "", login: ""}]
  },
  methods: {
    ...mapActions(["CopyAccountPrivs", "GetUsers"]),
    closeDialog() {
      this.newUsers =  [{name: "", login: ""}];
      this.$emit("close");
    },
    addUser() {
      const user = {
        name: "",
        login: "",
      };
      this.newUsers.push(user);
    },
    removeUser(index) {
      this.newUsers.splice(index, 1);
    },
    updateSelectedUser(user) {
      console.log(user);
      //this.$store.commit("addSelectedUser", user);
    },
    async saveDialog() {
/*       const user_id = this.selectedUser.id;
      const minActive = this.selectedUser.minActive;
      const maxActive = this.selectedUser.maxActive;
      const users = this.$store.getters.getSelectedUsers;
      await this.CopyAccountPrivs({ user_id, minActive, maxActive, users });*/
      await this.GetUsers();
      this.closeDialog(); 
    },
  },
  computed: {
    show: {
      get() {
        return this.shown;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    selectedUser: function () {
      this.newUsers =  [{name: "", login: ""}]
    },
  },  
};
</script>
